<template>
  <div>
    <LoadingOverlay v-if="isFetching" />
    <div class="addHospital pb-4" v-else>
      <ValidationObserver ref="addFacilityDetail" v-slot="{ handleSubmit }">
        <form id="addFacilityDetail" @submit.prevent="handleSubmit(onSubmit)">
          <CCardGroup class="has-loading-overlay">
            <CCard class="p-2">
              <CCardBody>
                <h4 class="pb-3 px-1 mb-0 underline">Facility</h4>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Facility Name</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider
                      :rules="{ required: true, facility_name: 100 }"
                      v-slot="{errors}"
                    >
                      <TextInput
                        name="facility_name"
                        :value="facilities.facility_name"
                        @input="handleInput"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Category</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <Select
                        name="organisation_type_id"
                        :value="facilities.organisation_type_id"
                        @input="handleChangeSelect"
                        :options="options && options['category'] ? options['category'] : []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="true"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Group</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <RadioButton
                        name="group"
                        :value="facilities.group"
                        :options="options && options['group'] ? options['group'] : []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Location</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                    <Select
                      name="location_id"
                      :value="facilities.location_id"
                      @input="handleChangeSelect"
                      :options="options && options['locations'] ? options['locations'] : []"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                      :disabled="true"
                      :error="errors[0]"
                    />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Facility Address</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <TextInput
                        name="address"
                        :value="facilities.address"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Type</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <RadioButton
                        name="type"
                        :value="facilities.type"
                        :options="options && options['type'] ? options['type'] : []"
                        @change="handleChangeRadio"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Website</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider
                      :rules="{ required: true, website_rule: /^(http\:\/\/|https\:\/\/)?([a-z0-9][a-z0-9\-]*\.)+[a-z0-9][a-z0-9\-]*$/ }"
                      v-slot="{errors}"
                    >
                      <TextInput
                        name="website"
                        :value="facilities.website"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Phone</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <PhoneInput
                        name="phone_no"
                        :value="facilities.phone_no"
                        :options="options && options['dialCode'] ? options['dialCode'] : []"
                        label="dialing_code"
                        @change="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="border-top">
                  <h4 class="pt-2 pb-3 px-1 mb-0 underline">Main Contact</h4>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Title</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <Select
                        name="title_id"
                        :value="facilities.title_id"
                        @input="handleChangeSelect"
                        :options="options && options['titles'] ? options['titles'] : []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="true"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">First Name</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <TextInput
                        name="first_name"
                        :value="facilities.first_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Family Name</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <TextInput
                        name="last_name"
                        :value="facilities.last_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Email</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required|email" v-slot="{errors}">
                      <TextInput
                        name="email"
                        :value="facilities.email"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Phone</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <PhoneInput
                        name="contact_phone_no"
                        :value="facilities.contact_phone_no"
                        :options="options && options['dialCode'] ? options['dialCode'] : []"
                        label="dialing_code"
                        @change="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="required col-lg-3 col-md-12">Mobile</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <PhoneInput
                        name="alt_phone_no"
                        :value="facilities.alt_phone_no"
                        :options="options && options['dialCode'] ? options['dialCode'] : []"
                        label="dialing_code"
                        @change="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="row mb-3">
                  <label class="col-lg-3 col-md-12">User Name</label>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <TextInput
                      name="username"
                      :value="facilities.username"
                      @input="handleInput"
                      :disabled="true"
                    />
                  </div>
                </CRow>
                <CRow class="border-top">
                  <a class="btn btn-lg toggle pt-2 pb-3 px-1 mb-0" @click="toggleAdvanced()">
                    <span class="ml-2 mr-2">Additional Information</span>
                    <CIcon v-if="!isAdvancedVisible" size="lg" name="cil-plus" />
                    <CIcon v-else size="lg" name="cil-minus" />
                  </a>
                </CRow>
                <div v-show="isAdvancedVisible">
                  <CRow class="row mb-3">
                    <label class="col-lg-3 col-md-12">No. of Beds</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <TextInput
                        name="num_beds"
                        type="number"
                        :value="facilities.num_beds"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-3 col-md-12">Facility License No</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <TextInput
                        name="facility_license_no"
                        :value="facilities.facility_license_no"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="col-lg-3 col-md-12">Additional Info</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <TextInput
                        name="additional_info"
                        :value="facilities.additional_info"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
          <div class="text-center mx-0 mt-4 pt-4 pb-4" style="padding-bottom:150px!important">
            <button class="btn rounded-0 px-5 btn-primary btn-lg" type="submit">Submit</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Vue from "vue";
import m from "moment";
import { times } from "lodash";
import { extend } from "vee-validate";
import { required, email, confirmed, min, max } from "vee-validate/dist/rules";
import { regex } from "vee-validate/dist/rules";
import { deepClone } from "@/helpers/helper.js";

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";

extend("required", { ...required, message: "This field is required" });
extend("facility_name", { ...max, message: "Max length: 100 character" });
extend("email", { ...email, message: "Invalid email" });
extend("website_rule", { ...regex, message: "Invaild Website" });

export default {
  components: {
    Select,
    TextInput,
    RadioButton,
    PhoneInput
  },
  computed: {
    ...mapGetters([
      "locations",
      "getCustomerDetails",
      "titles",
      "getOrganisationTypeList",
      "locationCountryInfo",
      "getCustomerDetailsById",
      "getImpersonationStatus",
      "dialingCodeWithCountryCode",
    ]),
    isFetching() {
      if (Object.keys(this.getFacility).length) return false;
      return true;
    },
    getFacility() {
      return (!this.getImpersonationStatus.impersonation)? this.getCustomerDetails : this.getCustomerDetailsById;
    },
    facilities() {
      const facilityData = this.getFacility;
      let facilitySource = {
        facility_name: facilityData.name,
        organisation_type_id: {
          code:
            facilityData.main_organisation.organisation_type
              .organisation_type_id,
          label:
            facilityData.main_organisation.organisation_type.organisation_type
        },
        group: facilityData.main_organisation.group,
        location_id: {
          code: facilityData.main_contact.location_id,
          label: facilityData.main_contact.location.location
        },
        address: facilityData.main_organisation.address,
        country_id: facilityData.main_organisation.country_id,
        type: this.facilityType(facilityData.government, facilityData.private),
        website: facilityData.main_organisation.website,
        phone_no: facilityData.main_organisation.phone_no,
        title_id: facilityData.main_contact.title
          ? {
              code: facilityData.main_contact.title.title_id,
              label: facilityData.main_contact.title.title
            }
          : null,
        first_name: facilityData.main_user.first_name,
        last_name: facilityData.main_user.surname,
        email: facilityData.main_contact.email,
        contact_phone_no: facilityData.main_contact.phone_no,
        alt_phone_no: facilityData.main_contact.alt_phone_no,
        username: facilityData.main_user.user_name,
        num_beds:
          facilityData.main_organisation.num_beds > 0
            ? facilityData.main_organisation.num_beds
            : null,
        facility_license_no: facilityData.main_organisation.facility_license_no,
        additional_info: facilityData.main_organisation.additional_info
      };
      return facilitySource;
    },
    options() {
      return {
        locations: this.locations || [],
        type: [
          { id: 1, label: "Goverment" },
          { id: 2, label: "Private" },
          { id: 3, label: "Both" }
        ],
        group: [
          { id: true, label: "Yes" },
          { id: false, label: "No" }
        ],
        category: this.getOrganisationTypeList || [],
        titles: this.titles || [],
        dialCode: this.dialingCodeWithCountryCode || []
      };
    }
  },
  data() {
    return {
      isAdvancedVisible: false,
      isFileError: false,
      payload: {}
    };
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "initFetchFacilityDetails",
      "showToast"
    ]),
    handleInput(name, value) {
      Vue.set(this.facilities, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.facilities, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null
      };
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.facilities, name, value);
      this.payload = {
        ...this.payload,
        [name]: val
      };
    },
    toggleAdvanced() {
      this.isAdvancedVisible = !this.isAdvancedVisible;
    },
    facilityType(isGovernment, isPrivate) {
      if (isGovernment && isPrivate) return 3;
      return isGovernment ? 1 : 2;
    },
    async onSubmit() {
      const isValid = await this.$refs.addFacilityDetail.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!"
        });
        return;
      }
      this.payload = {
        ...this.payload,
        facility_name: this.facilities.facility_name,
        organisation_type_id: this.facilities.organisation_type_id.code,
        location_id: this.facilities.location_id.code,
        government: this.facilities.type === 1 || this.facilities.type === 3,
        private: this.facilities.type === 2 || this.facilities.type === 3,
        group: this.facilities.group,
        address: this.facilities.address,
        country_id: this.facilities.location_id.code,
        website: this.facilities.website,
        phone_no: this.facilities.phone_no,
        num_beds: this.facilities.num_beds ? this.facilities.num_beds : 0,
        facility_license_no: this.facilities.facility_license_no,
        additional_info: this.facilities.additional_info,
        title_id: this.facilities.title_id.code,
        first_name: this.facilities.first_name,
        last_name: this.facilities.last_name,
        email: this.facilities.email,
        contact_phone_no: this.facilities.contact_phone_no,
        alt_phone_no: this.facilities.alt_phone_no
      };
      this.$emit("submit", this.payload);
    }
  },
  created() {
    this.initFetchFacilityDetails((this.getImpersonationStatus.impersonation)?this.getImpersonationStatus.customer_id:null);
  }
};
</script>

<style lang="scss" scoped>
.hint {
  opacity: 0.7;
}
.underline {
  text-decoration: underline;
}
</style>